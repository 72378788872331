<template>
    <breadcrumb btnText="返回" btnIcon="n-direction-left" @btn-click="$router.go(-1)" />
    <div class="form-box">
        <carousel-form 
            :btnLoading="loading"
            @submit="submit"
        />
    </div>
    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import CarouselForm from '@/components/forms/CarouselForm.vue';
import { Attention, CheckOne } from '@icon-park/vue-next';
export default {
    name: 'RecommendCarouselCreate',
    components: {
        Breadcrumb, CarouselForm,
        CarouselForm,
        Attention, CheckOne 
    },
    data(){
        return{
            loading: false,
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    methods:{
        submit(e){
            this.loading = true;
            this.$api.xrStoreHomeCarousel(e).then(res => {
                console.log(res)
                this.loading = false;
                if(res.msg === 'success'){
                    this.notification = {
                        show: true,
                        content: '成功',
                        state: 'success'
                    }
                    setTimeout(() => {
                        this.$router.go(-1);
                    },5000);
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.form-box{
    margin: 0 15px;
}
</style>